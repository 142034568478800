/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

import "fontsource-exo-2/900.css"
import "fontsource-source-sans-pro"

import "semantic-ui-less/semantic.less"
